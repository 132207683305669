export enum INVOICE_FEE_TYPES {
  VAT = "VAT",
  DISCOUNT = "DISCOUNT",
  DELIVERY = "DELIVERY",
  COUPON = "COUPON",
}

export interface InvoiceFee {
  amount: number;
  type: INVOICE_FEE_TYPES;
  title?: string;
}

export interface InvoiceItem {
  id: string;
  price: number;
  quantity: number;
  name: string;
}

export interface InvoiceReceiver {
  name: string;
  phone: string;
  email?: string;
}

export interface InvoiceSender {
  name: string;
  image: string;
  phone: string;
  address: string;
}

export enum INVOICE_STATUSES {
  PAID = "PAID",
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
}

export interface AccountInformation {
  bank_code: string;
  bank_name: string;
  account_name: string;
  account_number: string;
  image?: string;
}

export interface InvoiceInterface {
  fees: InvoiceFee[];
  items: InvoiceItem[];
  date_due: Date;
  date_created: Date;
  title: string;
  status: INVOICE_STATUSES;
  payments_enabled: boolean;
  total_amount: number;
  receiver: InvoiceReceiver;
  sender: InvoiceSender;
  store: string;
  invoice_id: string;
  is_draft?: boolean;
  settlement_account?: AccountInformation;
  id: string;
  receipt?: string;
  currency?: string;
}

export interface InvoiceForm {
  title: string;
  customer: string;
  date_created: Date;
  date_due: Date;
  items: InvoiceItem[];
  fees: InvoiceFee[];
  store_logo: string;
  store_address: string;
}

export interface ReceiptInterface {
  store: {
    image: string;
    name: string;
    address: string;
    phone: string;
  };
  customer: {
    name: string;
    email: string;
    phone: string;
  };
  total_amount: number;
  receipt_id: string;
  paid_at: string;
  payment_method: string;
  items: {
    name: string;
    quantity: number;
    price: number;
  }[];
  fees: {
    type: string;
    amount: number;
  }[];
  currency?: string;
}
